import { makeStyles } from '@material-ui/core/styles';
import React, { useEffect, useState } from "react";
import { styled } from '@mui/material/styles';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import SwipeableImageGallery from './swipeable-image-gallery';
import { GrClose } from 'react-icons/gr';
import appleIcon from '../assets/apple.png';
import googleIcon from '../assets/google.png';

const SaltIcon = require("../assets/salt.png");

const useStyles = makeStyles(() => ({
    container: {
        // margin: '10px',
    },
    no_bullet: {
        listStyle:'None',
    },
    routeBlock: {
        display: 'block',
        marginBottom: '10px',
        float: 'left',
    },
    topRow: {
        // display: 'inline',
        marginLeft: '15px'
    },
    routeHeader: {
        marginTop: '0px',
        marginBottom: '0px',
        fontWeight: '700',
        letterSpacing: '-1px',
    },
    completedBlock: {
        marginTop: '0px',
        marginBottom: '0px',
        fontWeight: '400',
    },
    link: {
        textDecoration: 'none',
        cursor: 'pointer',
        color: '-webkit-link',
    },
    modalLink: {
        textDecoration: 'none',
        cursor: 'pointer',
        color: '-webkit-link',
        // color: '#4F74AA',
        fontSize: '4vw',
    },
    leftBlock: {
        float: 'right',
        marginRight: '10px',
    },
    completionPercentage: {
        marginTop: '0px',
        marginBottom: '0px',
        paddingBottom: '0px',
        paddingTop: '0px',
        fontSize: '60px',
    },
    notesButton: {
        fontFamily: 'Poppins',
        borderRadius: '5px',
        paddingTop: '10px',
        paddingBottom: '10px',
        paddingRight: '3px',
        paddingLeft: '3px',
        border: '2px solid black',
        color: 'black',
        fontSize: '16px',
        border: 'none',
    },
    closeButtonContainer: {
        // position: 'absolute',
        // top: '8vw',
        // right: '8vw',
        float: 'left',
        paddingRight: '5vw',
        verticalAlign: 'center',
    },
    closeButton: {
        // paddingTop: '1vw',
        alignItems: 'center',
        justifyContent: 'center',
        verticalAlign: 'center',
        minWidth: '0px',
        padding: '0px',
        margin: '0px',
        border: 'none',
        backgroundColor: 'inherit',
    },
    header: {
        // float: 'left',
        // width: '90%',
        height: 'auto',
        paddingTop: '0px',
        marginBottom: '0px',
        marginTop: '0px',
        display: 'block',
        verticalAlign: 'top',
    },
    header2: {
        float: 'left',
        width: '75%',
        marginBottom: '0px',
        marginTop: '0px',
        display: 'block',
    },
    modalHeader: {
        // display: 'inline-block',
        display: 'flex',
        justifyContent: 'space-between',
        width: '95vw',
        marginBottom: '0px',
        marginTop: '0px',
        paddingTop: '0px',
        paddingLeft: '5vw',
        paddingTop: '5vw',

    },
    notes: {
        width: '90%',
        paddingLeft: '5vw',
        paddingRight: '5vw',
        // maxHeight: '20vh',
        // overflowX: 'hidden', 
        // overflowY: 'auto',
    },
    complaintText: {
        display: 'block',
        color: '#FF4F4B',

    },
    addressHeader: {
        display:'inline-block',
    },
    addressLine: {
        display:'block',
    },
    mapsButton: {
        borderRadius: '0px',
        border: 'none',
        // marginRight: '2vw',
        marginLeft: '0px',
        paddingTop: '1vw',
        paddingBottom: '1vw',
        // paddingLeft: '1vw',
        // paddingRight: '1vw',
        color: 'white',
        display: 'inline-flex',
        alignItems: 'center',
    },
    appleMaps: {
        backgroundColor: 'black',
    },
    googleMaps: {
        backgroundColor: '#4285F4',
    },
    mapButtonContainer: {
        display: 'flex',

        alignItems: 'center',
        margin: '0px',
        paddingRight: '5vw',
        // paddingLeft: '5vw',
        paddingTop: '2vw',
        paddingBottom: '2vw',
        width: '100%',
        justifyContent: 'space-evenly',
    },
    mapIcon: {
        width: '5vw',
        height: '5vw',
        paddingRight: '1vw',
        paddingLeft: '0px',
        marginLeft: '0px',
    },
    mapButtonText: {
        fontSize: '5vw',
        fontFamily: 'Poppins',
        fontWeight: '500',
        textDecoration: 'none',
    },
    notesBlock: {
        marginTop: '0px',
        marginBottom: '5px',
    },
    mapButtonAnchor: {
        textDecoration: 'none',
        alignItems:'center',
        textAlign:'center',
    },
    languageHeader: {
        fontSize: '3vw',
        paddingBottom: '0px',
        marginBottom: '-5vw',
    },
    dashed: {
        borderTop: '2px solid #bbb',
        padding: '0px',
        margin: '0px',
    },
    bottomContainer: {
        paddingRight: '5vw',
        float: 'right',
        paddingBottom: '5vw',
    },
    arrow: {
        color: 'black',
    }
  }));  

 const ModalStyle = {
    display: 'block',
    width: '100%',
    height: '100%',
    bgcolor: 'background.paper',
    borderRadius: '10px',
    boxShadow: 24,
    overflowY: 'auto',
    overflowX: 'hidden',
  };

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    fontFamily: 'Poppins',
    letterSpacing: '-1px',
    fontWeight: '400',
    // fontSize: '30px',
    [`&.${tableCellClasses.head}`]: {
        paddingRight: '10px',
        paddingLeft: '10px',
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
        fontSize: 20,
        fontWeight: 'bold',
    },
    [`&.${tableCellClasses.body}`]: {
        paddingTop: '20px',
        paddingBottom: '20px',
        paddingRight: '5px',
        paddingLeft: '0px',
        fontSize: 16,
    },
    }));

function InfoModal(props) {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const [images, setImages] = React.useState([]);

    useEffect(() => {
        var myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Authorization", "Basic YXBpa2V5OnB1YmxpY19XMTQyaFZYeGc2YVd1NkVZUDRkVVY2QVNQSk5Y");
        
        var requestOptions = {
          method: 'GET',
          headers: myHeaders,
          redirect: 'follow'
        };


        var address = props.address.substring(0, (props.address.length - 11));
        var newAddress = address.replaceAll(" ", "");
        console.log(newAddress)
        var path = `/uploads/property_info/${newAddress}`
        console.log("pat", path)
        fetch(`https://api.upload.io/v2/accounts/W142hVX/folders/children?folderPath=${path}`, requestOptions)
        .then(response => response.json().then((data) => {
            console.log("imagedata",data)
            if (data != undefined) {
                let imgs = data.children.map(chil => chil.fileUrl);
                if (imgs != undefined && imgs[0] != undefined) {
                    setImages(imgs);
                }
            }
        }))
        .catch(error => console.log('error', error));
    }, []);

    const handleOpen = () => {
      setOpen(true);
    };
    const handleClose = () => {
      setOpen(false);
    };

    const firstAddressLine = (a) => {
        let index = a.indexOf(',') + 1;
        let result = a.slice(0,index);
        return result;
    }

    const secondAddressLine = (a) => {
        let index = a.indexOf(',') + 1;
        let result = a.slice(index);
        return result;
    }

    const googleMaps = (address) => {
        return "https://maps.google.com/maps?q=" + address.replaceAll(" ","+")
    }

    const appleMaps = (address) => {
        return "https://maps.apple.com/?q=" + address.replaceAll(" ","+")
    }

    const isIOS = () => {
        let platform = navigator?.userAgentData?.platform || navigator?.platform || 'unknown'
        if 
        ((platform.indexOf("iPhone") != -1) || 
        (platform.indexOf("iPad") != -1)    ||
        (platform.indexOf("MacIntel") != -1)) {
            return true;
        }
        return false;
    }

    const notEmpty = (text) => {
        if (text != undefined && text != "") {
            return true;
        }
        return false;
    }

    return (
        <>
            <StyledTableCell>
                <div className={classes.addressHeader}>
                    {!props.notComplaint && 
                    (<a className={classes.complaintText}>Complaint</a>)}
                    <a 
                        className={classes.link}
                        onClick={handleOpen}
                        >
                            {/* <FaMapMarkerAlt /> */}
                            <a>{firstAddressLine(props.address)}</a>
                            <a className={classes.addressLine}>{secondAddressLine(props.address)}</a>
                    </a>
                </div>
                {props.salting && <div style={{alignContent: 'center'}}>
                    <img src={SaltIcon} className={classes.mapIcon}></img>
                </div>}
            </StyledTableCell>
            <StyledTableCell>
                <Button
                variant="contained"
                onClick={handleOpen}
                className={classes.notesButton}
                // style={{ backgroundColor: (props.isComplete ? '#93c47d': 'white')}}
                >
                    View
                </Button>
                <Drawer
                    anchor={'right'}
                    open={open}
                    onClose={handleClose}
                >
                {/* <Modal
                    // hideBackdrop
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="child-modal-title"
                    aria-describedby="child-modal-description"
                > */}
                    <Box sx={{...ModalStyle}}>
                        <div className={classes.modalHeader}>
                            <a className={classes.header}>
                                <a 
                                    className={classes.modalLink}
                                    href={props.href}>
                                    {/* <FaMapMarkerAlt /> */}
                                    {props.address}
                                </a>
                            </a>
                            <div className={classes.closeButtonContainer}>
                                <button className={classes.closeButton}  onClick={handleClose}>
                                    <GrClose className={classes.arrow} size={`6vw`}/>
                                </button>
                            </div>
                        </div>
                        { notEmpty(props.notes) && <div className={classes.notes}>
                            <i className={classes.languageHeader}>English</i>
                            <p className={classes.notesBlock}>
                            {props.notes}
                            </p>
                            <hr className={classes.dashed}></hr>
                            <i className={classes.languageHeader}>Spanish</i>
                            <p className={classes.notesBlock}>
                            {props.spanish_notes}
                            </p>
                            <hr className={classes.dashed}></hr>
                            <i className={classes.languageHeader}>Portuguese</i>
                            <p className={classes.notesBlock}>
                            {props.portuguese_notes}
                            </p>
                        </div>}
                        <div className={classes.mapButtonContainer}>
                            {isIOS() && (<a href={appleMaps(props.address)} className={classes.mapButtonAnchor}>
                                <button className={`${classes.mapsButton} ${classes.appleMaps}`}>
                                    <img src={appleIcon} className={classes.mapIcon}></img>
                                    <a className={classes.mapButtonText}>Apple Maps</a>
                                </button>
                            </a>)}
                            <a href={googleMaps(props.address)} className={classes.mapButtonAnchor}>
                                <button className={`${classes.mapsButton} ${classes.googleMaps}`}>
                                    <img src={googleIcon} className={classes.mapIcon}></img>
                                    <a className={classes.mapButtonText}>Google Maps</a>
                                </button>
                            </a>
                        </div>
                        <SwipeableImageGallery images={images}/>
                        <div className={classes.bottomContainer}>
                            <button className={classes.closeButton}  onClick={handleClose}>
                                <GrClose className={classes.arrow} size={`10vw`}/>
                            </button>
                        </div>
                </Box>
            {/* </Modal> */}
            </Drawer>
        </StyledTableCell>
      </>
    )
}

export default InfoModal;